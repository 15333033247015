<template>
  <v-row>
    <!-- header -->
    <mini-header :breadcrumbs="breadcrumbs" :hiddenTab="true" :dense="true">
      <template v-slot:main>
        <v-btn :to="{ name: 'TrackertList' }" outlined><v-icon size="18" left>mdi-email-fast</v-icon>Actividad</v-btn>
        <v-menu close-on-click close-on-content-click offset-y :nudge-bottom="20" transition="slide-y-transition" absolute z-index="1000" min-width="145">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" v-bind="attrs" v-on="on" color="primary">
              <v-icon left>mdi-calendar-blank</v-icon>
             {{selectedDays}}
              <v-icon size="18" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0 bgSearch">
            <v-list-item class="px-3" @click="selectedDays = 'Últimos 7 días'" active-class="transparent" :ripple="false">
              <v-list-item-title class="body-2"> Últimos 7 días</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item class="px-3" @click="goTo(1)" active-class="transparent" :ripple="false">
              <v-list-item-title class="body-2">Último mes</v-list-item-title>
            </v-list-item> -->
            <v-list-item class="px-3" @click="selectedDays = 'Últimos 30 días'" active-class="transparent" :ripple="false">
              <v-list-item-title class="body-2"> Últimos 30 días</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col class="mt-0 px-0">
      <v-row>
        <v-col v-for="statistic in statistics" :key="statistic.status">
          <v-card class="align-center d-flex px-3 py-3" flat>
            <v-sheet class="transparent mt-3 mb-4">
              <span class="secondary--text body-2 d-flex align-center">
                <v-icon class="mr-2" color="secondary" small>{{status.find(({key}) => statistic.status === key)?.icon}}</v-icon>
                {{ status.find(({key}) => statistic.status === key)?.key }}
              </span>
              <span class="d-block h4 font-weight-bold mt-2" :class="`${status.find(({key}) => statistic.status === key)?.colorText}--text`">{{statistic.count}}</span>
              <span class="d-block pt-1 body-2 secondary--text" v-if="statistic.docs">Documentos:
                <span class="font-weight-bold body-2">{{ statistic.docs }}</span>
              </span>
              <span class="d-block body-2 secondary--text" v-else> Tasa de entrega:
                <span class="font-weight-bold">{{statistic.rate}}</span>
              </span>
            </v-sheet>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="mt-5" flat>
        <v-col cols="12" class="px-5 pt-6 pb-0 text-right">
          <v-menu close-on-click close-on-content-click offset-y :nudge-bottom="20" transition="slide-y-transition" absolute z-index="1000" min-width="150">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" v-bind="attrs" v-on="on" outlined :width="160">
                {{name }}
                <v-spacer />
                <v-icon size="18" right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0 bgSearch">
              <v-list-item class="px-3" @click="!selected.includes('Todos') ? selected = ['Todos', 'Enviados', 'Recibidos', 'Abiertos', 'Cliqueados', 'Rebotados', 'SPAM'] : selected = []" active-class="transparent" :ripple="false">
                <v-list-item-title class="body-2 d-flex align-center">
                  <v-checkbox :input-value="isSeleted('Todos')" dense hide-details/>
                  Todos
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="px-3" @click="handleSeleted('Enviados')" active-class="transparent" :ripple="false">
                <v-list-item-title class="body-2 d-flex align-center">
                  <v-checkbox :input-value="isSeleted('Enviados')" dense hide-details/>
                  <v-avatar class="mr-2" color="primary" size="12"></v-avatar> Enviados
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="px-3" @click="handleSeleted('Recibidos')" active-class="transparent" :ripple="false">
                <v-list-item-title class="body-2 d-flex align-center">
                  <v-checkbox :input-value="isSeleted('Recibidos')" dense hide-details/>
                  <v-avatar class="mr-2" color="yellow" size="12"></v-avatar> Recibidos
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="px-3" @click="handleSeleted('Abiertos')" active-class="transparent" :ripple="false">
                <v-list-item-title class="body-2 d-flex align-center">
                  <v-checkbox :input-value="isSeleted('Abiertos')" dense hide-details/>
                  <v-avatar class="mr-2" color="success" size="12"></v-avatar> Abiertos
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="px-3" @click="handleSeleted('Cliqueados')" active-class="transparent" :ripple="false">
                <v-list-item-title class="body-2 d-flex align-center">
                  <v-checkbox :input-value="isSeleted('Cliqueados')" dense hide-details/>
                  <v-avatar class="mr-2" color="purple" size="12"></v-avatar> Cliqueados
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="px-3" @click="handleSeleted('Rebotados')" active-class="transparent" :ripple="false">
                <v-list-item-title class="body-2 d-flex align-center">
                  <v-checkbox :input-value="isSeleted('Rebotados')" dense hide-details/>
                  <v-avatar class="mr-2" color="fontBody" size="12"></v-avatar> Rebotados
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="px-3" @click="handleSeleted('SPAM')" active-class="transparent" :ripple="false">
                <v-list-item-title class="body-2 d-flex align-center">
                  <v-checkbox :input-value="isSeleted('SPAM')" dense hide-details/>
                  <v-avatar class="mr-2" color="error" size="12"></v-avatar> SPAM
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <div class="mx-auto pa-5">
          <chart-line
            axe-type-y="number"
            :background-color="'transparent'"
            :chart-data="receivedChartData"
            chart-id="documentsIssued"
            :number-rounded="true"
            :show-axe-x="true"
            :showAxeY="true"
            :show-draw-border-grid-lines-axe-y="true"
            :show-grid-lines-axe-y="true"
            :show-point="true"
            :borderWidth="1"
            :height="300"
            :hiddenTootltip="true"
            style="position:relative; height: 100%"
          />
        </div>
        <v-card-text class="pt-5 pb-10 px-8">
          <v-row>
            <v-col class="d-flex align-center body-2 fontBody--text" cols="2" v-for="statistic in statistics" :key="statistic.status">
              <v-card flat :disabled="!selected.includes('Todos') && !selected.includes(status.find(({key}) => statistic.status === key)?.key)">
                <v-avatar class="mr-2" :color="status.find(({key}) => statistic.status === key)?.colorText" size="12"></v-avatar>
                <span :style="!selected.includes(status.find(({key}) => statistic.status === key)?.key) ? 'color: #455A64 !important' : '' ">{{ status.find(({key}) => statistic.status === key)?.key }}</span>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import campaign from '@/data/campaignList' // example
import ChartLine from '@/components/charts/ChartLine'
import MiniHeader from '@/components/commons/MiniHeader'
import VEmptyState from '@/components/commons/VEmptyState'
import GenericViewMixin from '@/mixins/GenericViewMixin'

export default {
  components: {
    MiniHeader,
    VEmptyState,
    ChartLine
  },
  mixins: [
    GenericViewMixin
  ],
  data: () => ({
    selectedDays: ' Últimos 7 días',
    selected: ['Todos', 'Enviados', 'Recibidos', 'Abiertos', 'Cliqueados', 'Rebotados', 'SPAM'],
    campaignList: campaign,
    breadcrumbs: {
     main: 'Email tracker',
     children: [{ text: 'Monitor'}]
   },
   statistics: [
    {
        status: 'Enviados',
        count: 27.804,
        docs: 12.529
    },
    {
        status: 'Recibidos',
        count: 27.804,
        rate: '96%'
    },
    {
        status: 'Abiertos',
        count: 25.433,
        rate: '88%'
    },
    {
        status: 'Cliqueados',
        count: 14.368,
        rate: '49%'
    },
    {
        status: 'Rebotados',
        count: 958,
        rate: '3%'
    },
    {
        status: 'SPAM',
        count: 147,
        rate: '0,5'
    }
  ],
  status: [
    {
      key: 'Enviados',
      code: 'send',
      icon: 'mdi-email-fast',
      color: 'lightBlue',
      colorText: 'primary'
    },
    {
      key: 'Recibidos',
      code: 'received',
      icon: 'mdi-email-check',
      color: 'yellowLight',
      colorText: 'yellow'
    },
    {
      key: 'Abiertos',
      code: 'open',
      icon: 'mdi-email-open',
      color: 'greenExtraLight',
      colorText: 'success'
    },
    {
      key: 'Rebotados',
      code: 'reboot',
      icon: 'mdi-email-arrow-left',
      color: 'blueGreyLighten',
      colorText: 'fontBody'
    },
    {
      key: 'Cliqueados',
      icon: 'mdi-cursor-default-click',
      color: 'purpleLight',
      colorText: 'purpleMedium'
    },
    {
      key: 'SPAM',
      code: 'spam',
      icon: 'mdi-email-remove',
      color: 'redLight',
      colorText: 'redMedium'
    }
  ],
  receivedChartData: {
    labels: [
      "Miércoles",
      "Lunes",
      "Domingo"
    ],
    datasets: [
      {
        label: "Enviados",
        backgroundColor: '#0352CC',
        percent_change: '20.84',
        data: [27804, 10804, 25804]
      },
      {
        label: "Recibidos",
        backgroundColor: '#E8A803',
        percent_change: '-90.34',
        data: [30800, 16800, 15800]
      },
      {
        label: "Abiertos",
        backgroundColor: '#88B437',
        percent_change: '-90.34',
        data: [25433, 25800, 22000]
      },
      {
        label: "Cliqueados",
        backgroundColor: '#81007F',
        percent_change: '-90.34',
        data: [14368, 10000, 20433]
      },
      {
        label: "Rebotados",
        backgroundColor: '#455A64',
        percent_change: '-90.34',
        data: [3058, 9000, 10000]
      },
      {
        label: "SPAM",
        backgroundColor: '#BE2D2F',
        percent_change: '-90.34',
        data: [700, 7000, 2000, 7000]
      }
    ]
  }
  }),
  computed: {
    isSeleted () {
      return (val) => {
        return this.selected.includes(val)
      }
    },
    name () {
      if (this.selected.length === 7) return 'Todos'
      if (this.selected.length < 3) return this.selected.toString().replace(/,/g, ', ')
      else return `${this.selected.length} seleccionados`
    }
  },
  methods: {
    handleSeleted (name = '') {
      // if (name === 'Todos') this.selected.push['Todos', 'Enviados', 'Recibidos', 'Abiertos', 'Cliqueados', 'Rebotados', 'SPAM']
      if (!this.selected.includes(name)) {
        this.selected.push(name)
      } else {
        this.selected = this.selected.filter((item) => item !== name)
      }

      if (this.selected.length < 7) this.selected = this.selected.filter((item) => item !== 'Todos')
    }
  }
}
</script>